import styled from 'styled-components';
import ModalComponent from '../modal';
import ButtonType from '../buttonType';
import { BG_TOAST_ERROR_ICON, BG_TOAST_ICON, ERROR_ICON, SUCCESS_ICON } from '../../theme/images';
import { TextShadowCustom } from '../text';
import { Fragment } from 'react';
import Loading from '../loading';

const ContentStyled = styled.div`
  width: 100%;
  padding: 90px 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  text-align: center;
  > div {
    margin-bottom: 16px;
  }
  .icon-header {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .btn {
    font-weight: 700;
    font-size: 14px;
  }
  .text-content {
    margin-top: 10px;
  }
`;

export default function ModalToast({
  isOpen = false,
  onClose,
  type = 'success',
  textSuccess = 'キャンセル成功',
  textFail = '失敗',
  description = '',
  label = 'OK',
  loading = false,
  animation = false,
  textTitle = '',
  onOk,
}) {
  function getTextBasedOnType() {
    switch (type) {
      case 'success':
        return textSuccess;
      case 'error':
        return textFail;
      default:
        return textTitle || '';
    }
  }

  return (
    <ModalComponent
      open={isOpen}
      onCancel={() => {
        onClose && onClose();
      }}
      destroyOnClose
      className="custom-modal visible"
      padding={0}
      title={null}
      width={343}
    >
      <ContentStyled>
        {['error', 'success'].includes(type) && (
          <Fragment>
            <div className="bg-icon">
              <img className="icon-header" src={type === 'success' ? BG_TOAST_ICON : BG_TOAST_ERROR_ICON} alt="icon" />
              <img className="icon-header" src={type === 'success' ? SUCCESS_ICON : ERROR_ICON} alt="icon" />
            </div>
          </Fragment>
        )}

        <TextShadowCustom colorText="#ffffff" colorBox="#000000" fontSize="16px" fontWeight="900" uppercase={true}>
          {getTextBasedOnType()}
        </TextShadowCustom>
        <TextShadowCustom>{description}</TextShadowCustom>
        {!animation &&
          (loading ? (
            <Loading />
          ) : (
            <ButtonType
              fontSize="14px"
              fontWeight="700"
              className="btn"
              typeBtn="green"
              onClick={() => {
                onOk ? onOk() : onClose();
              }}
            >
              {label && label}
            </ButtonType>
          ))}
      </ContentStyled>
    </ModalComponent>
  );
}
